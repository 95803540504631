<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="salesProspect"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id})">
                                    {{item.sp_primary_details.account_name}}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>

                                    <v-btn
                                        v-if="item.merchant_status == 'Done'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/merchant-enrollment/'+item.id})"
                                        >
                                        <v-icon>mdi-eye</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>
                                    <v-btn
                                        v-if="item.merchant_status == 'Processing'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/merchant-enrollment/'+item.id})"
                                        >
                                        <v-icon>mdi-file-upload-outline</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>

                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default {
  name: 'Users',
  data () {
    return {
        search: '',
        fab: false,
        salesProspect: [],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Merchant Status',
                sortable: true,
                value: 'merchant_status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        dialog: false,
        sp_no: ''

    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      salesProspectingForm: 'salesProspecting/salesProspectingForm',
    })
  },
  async mounted () {
    this.reloadList()
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM')
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM_INITIAL_UPLOAD')
    this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER_DEFAULT')
    this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_FILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_FINAL_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BSP_DEFAULT')
    this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BOD_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
    this.$store.commit('bsp/UNSET_BSP_ID')
    this.$store.commit('bsp/SET_DET_DETAILS_DEFAULT')
    this.$store.commit('bsp/SET_DEF_FILES_DEFAULT')
    this.$store.commit('bsp/DET_FILES_DEFAULT')
    this.$store.commit('bsp/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_INITIALCOMMENT_DEFAULT')
    this.$store.commit('bsp/SET_COMPLIANCEUPLOAD_DEFAULT')
    this.$store.commit('salesProspecting/SET_RADIO_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_CHECKBOX_DEFAULT')
    this.$store.commit('merchantEnrollment/SET_MERCHANT_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS_DEFAULT')
  },
  methods: {
    async reloadList(){
        const res = await this.$store.dispatch('merchantEnrollment/doGetListOnboarding')
        this.salesProspect = res.data.result
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
  }
}
</script>